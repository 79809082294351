define('mvp-web/models/organization', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'mvp-web/utils/magic-ids'], function (exports, _relationships, _attr, _model, _magicIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isBlank = Ember.isBlank,
      isPresent = Ember.isPresent,
      observer = Ember.observer;
  exports.default = _model.default.extend({
    active: (0, _attr.default)('boolean'),
    addresses: (0, _relationships.hasMany)('address'),
    billingAddressId: (0, _attr.default)('string'),
    billingAddress: (0, _relationships.belongsTo)('address', { inverse: 'organizationBillingAddress' }),
    canopyConnectClientId: (0, _attr.default)('string'),
    canopyConnectLink: (0, _attr.default)('string'),
    canopyConnectClientSecret: (0, _attr.default)(),
    canopyConnectTeamId: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    displayEmailFooter: (0, _attr.default)('boolean'),
    customDisclaimerText: (0, _attr.default)('string'),
    customEmailFooterText: (0, _attr.default)('string'),
    token: (0, _attr.default)(),
    maskedNumber: (0, _attr.default)('string'),
    name: (0, _attr.default)(),
    notes: (0, _attr.default)(),
    organizationSetting: (0, _relationships.belongsTo)('organizationSetting'),
    settings: (0, _attr.default)(),
    phoneNumber: (0, _attr.default)('string'),
    phoneNumbers: (0, _relationships.hasMany)('phoneNumber'),
    primaryUserId: (0, _attr.default)('string'),
    shippingAddressId: (0, _attr.default)('string'),
    stripeAccount: (0, _relationships.belongsTo)('payment-methods/stripe'),
    stripeInvoices: (0, _relationships.hasMany)('stripeInvoice', { defaultValue: [] }),
    users: (0, _relationships.hasMany)('user'),
    subscription: (0, _relationships.belongsTo)('subscription'),
    subscriptionPlan: (0, _relationships.belongsTo)('subscriptionPlan'),
    subscriptionEvents: (0, _relationships.hasMany)('subscriptionEvent'),
    testEmailFooterText: (0, _attr.default)('string'),
    usState: (0, _attr.default)('string'),
    canceledAt: (0, _attr.default)('date'),
    timezone: (0, _attr.default)('string'),
    firstpromoterToken: (0, _attr.default)('string'),
    twilioMessageCount: (0, _attr.default)('number'),
    officialBusinessName: (0, _attr.default)('string'),
    taxId: (0, _attr.default)('string'),
    businessType: (0, _attr.default)('string'),
    websiteUrl: (0, _attr.default)('string'),
    textRequestApiKey: (0, _attr.default)('string'),
    textRequestSmsNumber: (0, _attr.default)('string'),
    twilioMobileVerificationNumber: (0, _attr.default)('string'),
    twilioSubaccountSid: (0, _attr.default)('string'),
    twilioSubaccountAuthToken: (0, _attr.default)('string'),
    a2pBrandSid: (0, _attr.default)('string'),
    twilioProfileRejected: (0, _attr.default)('boolean'),
    leadsPartnerId: (0, _attr.default)('number'),
    performologyClientId: (0, _attr.default)('string'),
    overrideRingcentralSmsNumber: (0, _attr.default)('string'),
    leadBalanceCents: (0, _attr.default)('number'),
    agencyToolchestId: (0, _attr.default)('string'),
    gotoConnectSmsNumber: (0, _attr.default)('string'),
    shouldEnableTwilioRetry: computed('a2pBrandSid', 'twilioProfileRejected', 'isSoleProprietor', 'taxId', function () {
      var hasNoTaxId = isBlank(this.get('taxId'));
      var isSoleProp = this.get('isSoleProprietor');
      var hasBrandSid = isPresent(this.get('a2pBrandSid'));
      return isSoleProp && hasNoTaxId && this.get('twilioProfileRejected') && hasBrandSid;
    }),
    parentOrganization: (0, _relationships.belongsTo)('organization', { inverse: 'childOrganizations' }),
    childOrganizations: (0, _relationships.hasMany)('organization', { inverse: 'parentOrganization' }),
    sentOrganizationInvites: (0, _relationships.hasMany)('organizationInvite', { inverse: 'inviter' }),
    receivedOrganizationInvites: (0, _relationships.hasMany)('organizationInvite', { inverse: 'invitee' }),
    pendingOrganizationInvites: computed.filterBy('receivedOrganizationInvites', 'status', 'pending'),
    twilioMessagePercentage: computed('twilioMessageCount', function () {
      var percentage = (this.get('twilioMessageCount') / 3000 * 100).toFixed(2);
      return percentage + '%';
    }),
    lastMonthTwilioMessageCount: (0, _attr.default)('number'),
    lastMonthTwilioMessagePercentage: computed('lastMonthTwilioMessageCount', function () {
      var percentage = (this.get('lastMonthTwilioMessageCount') / 3000 * 100).toFixed(2);
      return percentage + '%';
    }),
    currentMonthTextCountRetrievedAt: (0, _attr.default)('utc'),
    insuranceCarriers: (0, _relationships.hasMany)('insuranceCarrier'),
    insuranceCarrierIds: (0, _attr.default)(),
    handleInsuranceCarrierChange: observer('insuranceCarriers', function () {
      this.setInsuranceCarrierIds();
    }),

    // virtual attributes
    accountStatus: computed('active', function () {
      return this.get('active') ? 'Active' : 'Inactive';
    }),

    currentSubscription: computed.alias('subscriptionPlan'),
    activeSubscriptionEvents: computed.alias('subscriptionEvents'),

    inactive: computed('active', function () {
      return !this.get('active');
    }),
    primaryUser: computed('users', function () {
      if (this.get('primaryUserId')) {
        return this.get('store').findRecord('user', this.get('primaryUserId'));
      }
    }),

    activeUsers: computed.filterBy('users', 'active'),
    isSoleProprietor: computed.equal('businessType', 'Sole Proprietorship'),

    hasChildOrganizations: computed.notEmpty('childOrganizations'),

    // methods
    unverify: function unverify() {
      var adapter = this.store.adapterFor('organization');
      return adapter.unverify(this.get('id'));
    },
    updateSubscriptionPlan: function updateSubscriptionPlan(subscriptionPlanId, couponCode) {
      var adapter = this.store.adapterFor('organization');
      return adapter.updateSubscriptionPlan(this.get('id'), subscriptionPlanId, couponCode);
    },
    verify: function verify() {
      var adapter = this.store.adapterFor('organization');
      return adapter.verify(this.get('id'));
    },
    setInsuranceCarrierIds: function setInsuranceCarrierIds() {
      var insuranceCarrierIds = this.hasMany('insuranceCarriers').ids();
      insuranceCarrierIds.push(_magicIds.default.otherCarrierId, _magicIds.default.unknownCarrierId);
      this.set('insuranceCarrierIds', insuranceCarrierIds);
    }
  });
});