define('mvp-web/components/groups-table/group-non-customer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    session: service(),
    transitionState: 'lead',
    isLead: computed.equal('transitionState', 'lead'),
    displayCallback: computed.or('isLead', 'isPriority'),
    isCalifornia: computed.equal('session.currentUserOrganization.usState', 'CA'),
    isNotCalifornia: computed.not('isCalifornia'),

    columnCount: computed('isNotCalifornia', 'displayCallback', 'isCommercial', function () {
      var columns = 14; // Default number of columns

      if (this.get('isNotCalifornia')) columns++;
      if (this.get('displayCallback')) columns++;
      if (this.get('isCommercial')) columns++;

      return columns;
    }),

    showPipelinedDateColumn: computed('routeName', function () {
      var routeName = this.get('routeName');

      if (routeName === null || routeName === undefined) {
        return false;
      }

      return this.get('routeName').includes('pipelined');
    }),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});