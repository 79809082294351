define('mvp-web/adapters/oauth', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$String = Ember.String,
      singularize = _Ember$String.singularize,
      camelize = _Ember$String.camelize;
  exports.default = _application.default.extend({
    azureAuth: function azureAuth(_ref) {
      var code = _ref.code;

      var url = this.buildURL('oauth') + '/azure?auth_code=' + code;
      return this.ajax(url, 'POST');
    },
    googleAuth: function googleAuth(_ref2) {
      var code = _ref2.code;

      var url = this.buildURL('oauth') + '/google?auth_code=' + code;
      return this.ajax(url, 'POST');
    },
    ringcentralAuth: function ringcentralAuth(_ref3) {
      var code = _ref3.code;

      var url = this.buildURL('oauth') + '/ringcentral?auth_code=' + code;
      return this.ajax(url, 'POST');
    },
    deleteRingcentralAuth: function deleteRingcentralAuth() {
      var url = this.buildURL('oauth') + '/ringcentral';
      return this.ajax(url, 'DELETE');
    },
    deleteGoogleAuth: function deleteGoogleAuth() {
      var url = this.buildURL('oauth') + '/google';
      return this.ajax(url, 'DELETE');
    },
    pathForType: function pathForType(type) {
      var camelized = camelize(type);
      return singularize(camelized);
    },
    gotoConnectAuth: function gotoConnectAuth(_ref4) {
      var code = _ref4.code;

      var url = this.buildURL('oauth') + '/goto_connect?auth_code=' + code;
      return this.ajax(url, 'POST');
    }
  });
});